<template>
    <div class="layout-px-spacing dash_1" v-if="getterMe != null">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:void(0)">Dashboard</a></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>

 
        </teleport>

        <div class="row layout-top-spacing">


            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
                <div class="widget widget-transaction">
                    <div class="widget-heading">
                        <h5>Transactions</h5>
                        <div class="task-action">
                            <div class="dropdown btn-group">
                                <a href="javascript:;" id="ddlTransactions" class="btn dropdown-toggle btn-icon-only" data-bs-toggle="dropdown" aria-expanded="false">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-more-horizontal"
                                    >
                                        <circle cx="12" cy="12" r="1"></circle>
                                        <circle cx="19" cy="12" r="1"></circle>
                                        <circle cx="5" cy="12" r="1"></circle>
                                    </svg>
                                </a>
                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="ddlTransactions">
                                    <li>
                                        <router-link to="/transactions" class="dropdown-item">View All</router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="widget-content">
                        <div v-for="item in list6ItemTransaction" class="transactions-list">
                            <div>
                                <span class="b-avatar icon-fill-warning me-2 badge-warning rounded-circle">
                                    <span class="b-avatar-custom"
                                    ><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="19"
                                        height="19"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-home"
                                    >
                                            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                                            <polyline points="9 22 9 12 15 12 15 22"></polyline>
                                        </svg>
                                    </span>
                                </span>
                                <div class="t-name">
                                    <h4>{{ item.description }}</h4>
                                    <p>{{ formatCreatedAt(item.created) }}</p>
                                </div>
                            </div>
                            <template v-if="item.amount.toString().includes('-')">
                                <div class="text-danger">
                                    -{{ convertAmountTransaction(item.amount.toString().replace('-', '').replace('+', '')) }} {{getterCurrency.lower}}
                                </div>
                            </template>
                            <template v-if="!item.amount.toString().includes('-')">
                                <div class="text-success">
                                   +{{ convertAmountTransaction(item.amount.toString().replace('-', '').replace('+', '')) }} {{getterCurrency.lower}}
                                </div>
                            </template>
                        </div>
                        <div v-if="!list6ItemTransaction.length" style="text-align: center">
                            <img style="width: 250px" src="@/assets/images/notran.svg" alt="admin-profile" class="img-fluid" />
                            <!--                            <p class="text-center">No transactions !</p>-->
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
                <div class="widget widget-wallet-balance">
                    <div class="widget-heading d-block">
                        <div class="wallet-usr-info">
                            <div class="usr-name">
                                <span><img src="@/assets/images/profile-32.jpeg" alt="admin-profile" class="img-fluid" /> {{getterMe.email}}</span>
                            </div>
                            <div class="add">
                                <span>
                                    <router-link style="color:white" to="/payment">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather feather-plus"
                                        >
                                        <line x1="12" y1="5" x2="12" y2="19"></line>
                                        <line x1="5" y1="12" x2="19" y2="12"></line></svg
                                        >
                                    </router-link>
                                </span>
                            </div>
                        </div>
                        <div class="wallet-balance">
                            <p>Wallet Balance</p>
                            <h5 v-if="ending_balance != null">{{ convertCurrency(ending_balance * 1) }} {{getterCurrency.lower}}</h5>
                        </div>
                    </div>

                    <div class="widget-amount" v-if="firstTransactionReceived != null" >
                        <div class="w-a-info funds-received me-3">
                            <span
                                >Received
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-chevron-up text-success float-end"
                                >
                                    <polyline points="18 15 12 9 6 15"></polyline></svg
                            ></span>
                            <p v-if="firstTransactionReceived !== undefined && firstTransactionReceived != null && firstTransactionReceived.amount !== undefined">
                                {{ convertCurrency(firstTransactionReceived.amount * 1 ) }}{{getterCurrency.lower}}
                            </p>
                        </div>
                        <div v-if="firstTransactionSpent != null" class="w-a-info funds-spent">
                            <span
                                >Spent
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-chevron-down text-danger float-end"
                                >
                                    <polyline points="6 9 12 15 18 9"></polyline></svg
                            ></span>
                            <p v-if="firstTransactionSpent.amount !== undefined && firstTransactionReceived != null && firstTransactionReceived.amount !== undefined">
                                {{ convertCurrency(firstTransactionSpent.amount.toString().replace('-','') * 1 ) }}{{getterCurrency.lower}}
                            </p>
                        </div>
                    </div>

                    <div class="widget-content">
                        <div class="invoice-list">
                            <div class="inv-detail mb-4">
                                <h6 v-if="recentTransactionReceived.length" style="color: #4361ee">Recent Received</h6>
                                <div v-for="item in recentTransactionReceived" class="info-detail-1">
                                    <p>{{ item.description || 'Latest Received'}}</p>
                                    <p>
                                        <span class="bill-amount">
                                            +{{ convertAmountTransaction(item.amount.toString().replace('-', '').replace('+', '')) }} {{getterCurrency.lower}}
                                        </span>
                                    </p>
                                </div>

                                <h6 v-if="recentTransactionSpent.length" class="mt-3" style="color: #4361ee">Recent Spent </h6>
                                <div class="info-detail-2"  v-for="item in recentTransactionSpent">
                                    <p>{{ item.description || 'Latest Spent' }}</p>
                                    <p>
                                        <span class="bill-amount">
                                            -{{ convertAmountTransaction(item.amount.toString().replace('-', '').replace('+', '')) }} {{getterCurrency.lower}}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div v-if="list6ItemTransaction.length" class="inv-action">
                                <router-link to="/transactions" class="btn view-details btn-outline-secondary">View Details</router-link>
                                <router-link to="/invoice" class="btn pay-now btn-outline-success">View Invoice</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                <div class="widget widget-recent-orders">
                    <div class="widget-heading d-flex justify-content-between">
                        <h5>Recent Invoices</h5>
                        <div class="dropdown btn-group">
                            <a href="javascript:;" id="ddlTransactions" class="btn dropdown-toggle btn-icon-only" data-bs-toggle="dropdown" aria-expanded="false">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-more-horizontal"
                                >
                                    <circle cx="12" cy="12" r="1"></circle>
                                    <circle cx="19" cy="12" r="1"></circle>
                                    <circle cx="5" cy="12" r="1"></circle>
                                </svg>
                            </a>
                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="ddlTransactions">
                                <li>
                                    <router-link to="/invoice" class="dropdown-item">View All</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="widget-content table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th><div class="th-content">ID</div></th>
                                    <th><div class="th-content">Amount Due</div></th>
                                    <th><div class="th-content">Status</div></th>
                                    <th><div class="th-content">Invoice PDF</div></th>
                                    <th><div class="th-content">Invoice URL</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in list6ItemInvoice">
                                    <td>
                                        {{item.id}}
                                    </td>
                                    <td>
                                        <div class="td-content">
                                            {{ convertCurrency(parseInt(item.amount_due)) }} {{getterCurrency.lower}}
                                        </div>
                                    </td>
                                    <td v-if="item.amount_due == 0" aria-colindex="2" role="cell" class="">
                                        <span class="badge outline-badge-info">Paid</span>
                                    </td>
                                    <td v-if="item.amount_due > 0" aria-colindex="2" role="cell" class="">
                                        <span class="badge outline-badge-danger">UnPaid</span>
                                    </td>
                                    <td><div style="padding-left: 30px" class="td-content text-primary">
                                        <a :href="item.invoice_pdf" class="text-primary">
                                            <i class="fa-solid fa-download text-primary"></i>
                                        </a>
                                    </div></td>
                                    <td><div style="padding-left: 15px" class="td-content text-primary">
                                        <a target="_blank" :href="item.hosted_invoice_url" class="text-primary">
                                            VIEW
                                        </a>
                                    </div></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import '@/assets/sass/widgets/widgets.scss';
    import { computed, ref } from 'vue';
    import { useStore } from 'vuex';
    import ApexChart from 'vue3-apexcharts';

    import { useMeta } from '@/composables/use-meta';
    useMeta({ title: 'EPIS CLOUD' });

    const store = useStore();
</script>
<script>
import {mapActions, mapGetters} from "vuex";
import router from "@/router";

export default {
    name: 'login_boxed',
    data() {
        return {
            list6ItemTransaction: [],
            list6ItemInvoice: [],
            
            firstTransactionSpent: null,
            firstTransactionReceived: null,
            
            recentTransactionReceived: [],
            recentTransactionSpent: [],
            
            ending_balance: 0,
        }
    },
    created() {
        this.getTransactions(this.getterMe.id).then(res => {
            console.log('getTransactions', res)
            
            for (let i = 0; i < res.data.result.length; i++) {
                if (i > 5) break
                const val = res.data.result[i]
                this.list6ItemTransaction.push(val)
            }
            for (let i = 0; i < res.data.result.length; i++) {
                if (res.data.result[i].amount.toString().includes('-')){
                    // this.firstTransactionSpent = res.data.result[i];
                    this.recentTransactionSpent.push(res.data.result[i])
                    
                    if (this.recentTransactionSpent.length == 2){
                        break;
                    }
                }
            }
            for (let i = 0; i < res.data.result.length; i++) {
                if (!res.data.result[i].amount.toString().includes('-')){
                    this.recentTransactionReceived.push(res.data.result[i])

                    if (this.recentTransactionReceived.length == 2){
                        break;
                    }
                }
            }
            
            // 
            this.ending_balance = res.data.result[0].ending_balance
            //
            console.log('this.recentTransactionReceived', this.recentTransactionReceived)
            console.log('this.recentTransactionSpent', this.recentTransactionSpent)
            
        }).catch(e => {
            console.log(e)
        })
        this.getInvoices().then(res => {
            for (let i = 0; i < res.data.result.length; i++) {
                if (i > 5) break
                const val = res.data.result[i]
                this.list6ItemInvoice.push(val)
            }
        }).catch(e => {
            console.log(e)
        })
    },
    computed:{
        ...mapGetters(['getterMe','getterCurrency'])
    },
    methods: {
        ...mapActions(['getTransactions','getInvoices']),
        convertAmountTransaction(x) {
            return parseInt(x).toLocaleString('vi-VN')
        },
        convertCurrency(x) {
            return x.toLocaleString('vi-VN')
        },
        formatCreatedAt(date){
            date = new Date(date);
            let month = date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth();
            let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
            let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
            let min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
            let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            return date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + s;
        }
    },
}
</script>
